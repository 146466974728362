<template>
  <div class="calendar-component">
    <div class="calendar-component__header">
      <selectComponent placeholder="Выберите мероприятие" :options="eventTypeList" v-model="filter.eventTypeId" />
      <ButtonStock title="Перейти" @click="loadEvents" />
    </div>
    <div class="calendar-component__body">
      <!-- calendar head -->
      <div v-show="changeView" class="calendar-head">
        <!-- Селектор перелистывает недели месяца и показывает неободимую таблицу заполненную -->
        <!-- Скрывается при переключении вида -->
        <div v-show="changeView" class="calendar-selector">
          <div class="yearsMonth">
            <span @click="updateMonth('prev')" class="arrow prev"></span>
            <span> {{ todayMonth }} {{ todayYear }} </span>
            <span @click="updateMonth('next')" class="arrow next"></span>
          </div>
          <div class="weeks">
            <span @click="updateCounterWeeks('prev')" class="arrow prev"></span>
            <span>{{ counterWeeks }}-я неделя</span>
            <span @click="updateCounterWeeks('next')" class="arrow next"></span>
          </div>
        </div>
        <!-- Иконки сменяются при смене вида -->
        <div class="calendar-icons">
          <div v-show="changeView">
            <IconComponent @click="changeView = !changeView" name="event-note" />
          </div>
        </div>
      </div>

      <!-- Calendar second view -->
      <div ref="outsideCalendar" v-show="!changeView" class="calendar-plug">
        <div v-show="!changeView">
          <IconComponent @click="changeView = !changeView" name="tableOne" />
        </div>
        <v-calendar
          ref="calendar"
          class="v-calendar__activity"
          :attributes="attributes"
          @update:from-page="loadCalendar"
        >
          <template #day-popover="{ dayTitle, attributes }">
            <IconComponent @click="doClickOutsideCalendar" name="clear_cross" />
            <div class="day-popover__item" v-for="{ key, customData } in attributes" :key="key">
              <div class="day-popover__item-body">
                <!-- Тип мероприятия -->
                <p class="type">{{ customData.eventType.name }}</p>
                <!-- Дата мероприятия -->
                <p class="date">{{ dayTitle }}</p>
                <!-- Проверка наличия времени -->
                <p class="time-container">
                  <span class="time-block">
                    <span v-if="customData.time != ''" class="time">{{ customData.time }}</span>
                    <span>
                      <span>Регистрация</span>
                      <span v-if="customData.isRegistrationOpen"> открыта</span>
                      <span v-else> закрыта</span>
                    </span>
                  </span>
                </p>
                <!-- Текст мероприятия -->
                <p class="about">{{ customData.name }}</p>
                <!-- Просмотр мероприятия -->
                <ButtonStock title="Просмотр" @click="editCard(customData.id)" />
              </div>
            </div>
          </template>
        </v-calendar>
      </div>

      <!-- Calenar first view -->
      <div v-show="changeView" class="calendar-table table-component">
        <div class="table-vidjet">
          <table>
            <thead>
              <tr>
                <th>Содержание</th>
                <th>Дата</th>
                <th>Место</th>
                <th>Мероприятие</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in tableArray" :key="item.id" @dblclick="editCard(item.id)">
                <td>{{ item.name }}</td>
                <td>
                  <template v-for="(date, index) in item.eventDatesList" :key="date">
                    <template v-if="index > 0">, </template>
                    <span>{{ $momentFormat(date, 'DD MMMM YYYY HH:mm') }}</span>
                  </template>
                </td>
                <td>{{ item.place }}</td>
                <td>{{ item.eventType.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-show="changeView" class="calendar-component__footer">
      <ButtonStock title="Все мероприятия" @click="goTo" />
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  import selectComponent from '@/common/components/selectComponent.vue';
  import Constants from '@/common/constants';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/widgetElement';

  export default {
    name: 'Widget6',
    components: {
      ButtonStock,
      selectComponent,
      IconComponent,
    },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    watch: {
      countryId: function (/*newValue*/) {
        this.loadEvents();
      },
      counterMonth: function (/*newValue*/) {
        this.loadEvents();
      },
      counterYear: function (/*newValue*/) {
        this.loadEvents();
      },
    },
    data() {
      return {
        eventTypeList: [],
        filter: {
          eventTypeId: null,
          month: null,
          year: null,
        },

        changeView: true,
        // То что отрисованно будет
        tableArray: [],
        // Данные с бека
        getEventsData: [],
        todos: [],
        counterWeeks: 1,
        counterMonth: 0,
        counterYear: 0,
        month: Constants.monthListSimple,
      };
    },
    methods: {
      loadCalendar(date) {
        this.counterMonth = date.month - 1;
        this.counterYear = date.year;
        this.counterWeeks = 1;
      },

      doClickOutsideCalendar() {
        this.$refs.outsideCalendar.click();
      },

      updateCalendarTable(week) {
        console.log(week, '---- updateCalendarTable');
        let totalDaysInWeek = 7;
        let rangeFrom = 1;
        let rangeTo = 0;

        switch (week) {
          case 1:
            rangeTo = totalDaysInWeek * 1;
            this.tableArray = this.filteredArray(rangeFrom, rangeTo);
            break;

          case 2:
            rangeFrom = totalDaysInWeek * 1 + 1;
            rangeTo = totalDaysInWeek * 2;
            this.tableArray = this.filteredArray(rangeFrom, rangeTo);
            break;

          case 3:
            rangeFrom = totalDaysInWeek * 2 + 1;
            rangeTo = totalDaysInWeek * 3;
            this.tableArray = this.filteredArray(rangeFrom, rangeTo);
            break;

          case 4:
            rangeFrom = totalDaysInWeek * 3 + 1;
            rangeTo = 31;
            this.tableArray = this.filteredArray(rangeFrom, rangeTo);
            break;
        }
      },

      filteredArray(rangeFrom, rangeTo) {
        let year = this.counterYear;
        let month = this.counterMonth + 1;
        return this.getEventsData.filter((item) => {
          let dateFrom = new Date(`${year}-${month}-${rangeFrom}`);
          let dateTo = new Date(`${year}-${month}-${rangeTo} 23:59`);
          var from = moment(dateFrom).format('YYYY/MM/DD');
          var to = moment(dateTo).format('YYYY/MM/DD');
          return item.eventDatesList.some(
            (x) => moment(x).format('YYYY/MM/DD') >= from && moment(x).format('YYYY/MM/DD') <= to,
          );
          //let currentDate = new Date(item.date);
          //return currentDate >= dateFrom && currentDate <= dateTo;
        });
      },

      updateCounterWeeks(move) {
        let totalWeeks = 4,
          minWeeks = 1;

        switch (move) {
          case 'prev':
            if (this.counterWeeks != minWeeks) {
              this.counterWeeks--;
              this.updateCalendarTable(this.counterWeeks);
            }
            break;

          case 'next':
            if (this.counterWeeks != totalWeeks) {
              this.counterWeeks++;
              this.updateCalendarTable(this.counterWeeks);
            }
            break;
        }
      },

      updateMonth(move) {
        switch (move) {
          case 'prev':
            if (this.counterMonth < 0 || this.counterMonth == 0) {
              this.counterYear--;
              this.counterMonth = 11;
              break;
            }
            this.counterMonth--;
            this.counterWeeks = 1;
            this.updateCalendarTable(this.counterWeeks);
            break;

          case 'next':
            if (this.counterMonth > 11 || this.counterMonth == 11) {
              this.counterYear++;
              this.counterMonth = 0;
              break;
            }
            this.counterMonth++;
            this.counterWeeks = 1;
            this.updateCalendarTable(this.counterWeeks);
            break;
        }

        const calendar = this.$refs.calendar;
        calendar.move({ month: this.counterMonth + 1, year: this.counterYear });
      },

      getMonthAndYear() {
        let date = new Date();
        this.counterMonth = date.getMonth();
        this.counterYear = date.getFullYear();
      },

      loadEvents() {
        var params = {
          countryId: this.countryId,
          eventTypeId: this.filter.eventTypeId,
          month: this.counterMonth + 1,
          year: this.counterYear,
          //dateFrom: null,
          //dateTo: null
        };
        API.getEvents(params).then((response) => {
          this.getEventsData = response.data;
          this.updateCalendarTable(this.counterWeeks);
          this.todos = [];
          this.getEventsData.forEach((el) => {
            if (el.eventDatesList) {
              el.eventDatesList.forEach((d) => {
                var eventToPush = Object.assign({}, el);
                eventToPush.eventDatesList = [d];
                eventToPush.time = moment(d).format('HH:mm');
                this.todos.push(eventToPush);
              });
            }
          });
          console.log(this.todos);
        });
      },

      goTo() {
        this.$router.push({ name: 'Events' });
      },
      editCard(id) {
        this.$router.push({ name: 'EventCard', params: { id: id, action: 'view' } });
      },
    },

    computed: {
      todayMonth() {
        return this.month[this.counterMonth];
      },
      todayYear() {
        return this.counterYear;
      },
      attributes() {
        // return [
        //   // Attributes for todos
        //   ...this.todos.map(todo => ({
        //     dates: todo.dates,
        //     dot: {
        //       color: todo.color,
        //       class: todo.isComplete ? 'opacity-75' : '',
        //     },
        //     popover: {
        //       label: todo.description,
        //       visibility: 'click',
        //       isInteractive: true,
        //     },
        //     customData: todo,

        //   })),
        // ];
        return [
          // Attributes for todos
          ...this.todos.map((todo) => ({
            dates: todo.eventDatesList,
            dot: {
              color: todo.color,
              class: todo.statusId == 3 ? 'opacity-75' : '',
            },
            popover: {
              label: todo.name,
              visibility: 'click',
              isInteractive: true,
            },
            customData: todo,
          })),
        ];
      },
    },

    created() {
      Utils.loadSelectOptions('mpkEventTypeList', this.eventTypeList);
      this.getMonthAndYear();
      this.updateCalendarTable(this.counterWeeks);

      this.loadEvents();
    },
  };
</script>

<style lang="scss" scoped>
  .calendar {
    &-component {
      &__header,
      &__footer {
        padding: 16px 20px;
      }
      &__header {
        display: flex;
        .select2::v-deep {
          // Контейнер ширина
          .select2-container {
            width: 100% !important;
            max-width: 498px !important;
          }
        }
      }
      &__footer {
        .btn-primary {
          width: 100%;
        }
      }
    }
    &-table {
      table {
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        color: #343c44;
        thead,
        tbody tr {
          display: table;
          width: 100%;
          table-layout: fixed;
        }
        thead {
          th {
            padding: 10px;
            border-bottom: 1px solid #dedede;
            &:nth-of-type(1) {
              width: 20%;
            }
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4) {
              width: 10%;
            }
          }
        }
        tbody {
          display: block;
          overflow-x: hidden;
          overflow-y: auto;
          max-height: 255px;
          tr {
            td {
              padding: 10px 0px 10px 10px;
              &:nth-of-type(1) {
                width: 20%;
              }
              &:nth-of-type(2),
              &:nth-of-type(3),
              &:nth-of-type(4) {
                width: 10%;
              }
            }
            &:nth-of-type(even) {
              background: #ededed;
            }
          }
        }
      }
    }
    &-head,
    &-selector,
    &-icons {
      display: flex;
    }
    &-head {
      padding: 12px 14px;
      background: linear-gradient(270deg, #00b2aa 0%, #0078c8 100%);
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      span {
        font-weight: 700;
        letter-spacing: 0.25px;
      }
    }
    &-selector {
      margin-left: auto;
      .yearsMonth,
      .weeks {
        display: flex;
        align-items: center;
      }
      .arrow {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 1px;
        border-left: 3px solid #ffffff;
        border-bottom: 3px solid #ffffff;
        cursor: pointer;
        &.prev {
          transform: rotate(45deg);
          margin-right: 20px;
        }
        &.next {
          transform: rotate(225deg);
          margin-left: 20px;
        }
      }
      .yearsMonth {
        margin-right: 22px;
      }
    }
    &-icons {
      margin-left: auto;
    }
    &-plug {
      .v-calendar__activity {
        width: 100%;
      }
    }
  }
  @media (max-width: 320px) {
    .calendar-component__header {
      flex-wrap: wrap;
      .btn-primary {
        width: 100%;
        margin-top: 2px;
      }
    }
    .calendar-selector[data-v-4418a876] {
      flex-wrap: wrap;
    }
    .calendar-table table tbody {
      max-height: 225px;
    }
  }
</style>

<style lang="scss">
  .calendar {
    &-plug {
      position: relative;
      .icon {
        position: absolute;
        top: 12px;
        right: 14px;
        z-index: 1;
      }
      .v-calendar__activity {
        border: none;
        .vc-weeks {
          padding: 18px 8px 0px 8px;
        }
        .vc-header {
          padding: 14px 16px;
        }
        .vc-day,
        .vc-weekday {
          margin-bottom: 9.5px;
        }
        .vc-arrows-container {
          width: 30%;
          top: 0px;
          left: 225px;
        }
        .vc-day-popover-container {
          max-height: 160px;
          overflow-x: auto;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .calendar-plug .v-calendar__activity .vc-arrows-container {
      width: 100%;
      top: 0px;
      left: 0px;
      justify-content: space-around;
    }
  }
</style>
